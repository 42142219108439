<script>
import SuppliersApi from '@/api/suppliers'
import SuppliersForm from './SuppliersForm'

export default {
  extends: SuppliersForm,

  mixins: [SuppliersApi],

  data() {
    return {
      isNew: false

    }
  },

  computed: {
    warningButtonText() {
      if (this.isManager) {
        return null
      }

      return this.form.disabled_comment ? 'Разблокировать': 'Заблокировать'
    }
  },

  created() {
    this.getSupplier(this.$route.params.id).then(response => {
      this.form = {
        ...response
      }
    })
  },

  methods: {
    handleBlockSupplierClick() {
      if (!this.form.disabled_comment) {
        this.isShowDialog = true
      } else {
        this.changeStatus()
      }
    },

    changeStatus(message) {
      if (!this.form.disabled_comment && message.length < this.minMessageLength) {
        return
      }

      this.form.disabled_comment = message || ''

      this.isSubmit = true

      this.changeSupplierStatus(this.form.id, this.form)
        .then(() => {
          window.snackbar( `Поставщик ${this.form.disabled_comment ? 'заблокирован' : 'разблокирован' }`, 'info')
        }).finally(() => { this.isSubmit = false })

      this.isShowDialog = false
    },

    handleFormSubmit() {
      if (this.isManager) {
        this.changeWerks()
        return
      }

      if (this.$v.$invalid) {
        this.$refs.form.validate()
        window.snackbar('Ошибки в форме', 'error')
        return
      }

      const params = {
        ...this.form,
        disabled_comment: null
      }

      this.isSubmit = true

      this.updateSupplier(this.form.id, params)
        .then(() => {
          window.snackbar('Поставщик успешно изменен', 'info')
          this.$router.push({ name: 'suppliers' })
        })
        .finally(() => { this.afterSubmit() })
    },

    changeWerks() {
      this.$apiMethods.suppliers
        .changeWerks(this.form.id, this.form.werk_ids || [])
        .then(() => {
          window.snackbar('Поставщик успешно изменен', 'info')
          this.$router.push({ name: 'suppliers' })
        })
    }
  }
}
</script>
